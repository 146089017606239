<template>
  <div class="web-access">
    <PictimeSiretModal v-show="isSiretModalOpen" :cms-block="blocks.length > 6 && blocks[6].content"/>
    <PictimeCodClientModal v-show="isCodClientModalOpen" :cms-block="blocks.length > 7 && blocks[7].content"/>

    <div class="web-access__title uppercase">{{ $t("Create my web access") }}</div>
    <div class="web-access__subtitle">{{ $t("Does your society already have a client account ?") }}</div>
    <div v-if="formError" class="error">
      <SvgImage
        class="error__icon"
        height="20"
        icon="Icon_attention"
        width="24"
      />
      {{ $t(formError) }}
      <span></span>
    </div>

    <div class="accordion__container">
      <div @click="checkClient">
        <div :style="{cursor: 'pointer'}" class="accordion__title-container">
          <div>
            <input id="client" :checked="checkedClient" class="accordion__radio" name="client" type="radio"
                   value="client"/>
            <label for="client"></label>
            <span :class="checkedClient && 'accordion__title'">{{ $t("Yes, I am already client") }}</span>
          </div>
        </div>
      </div>
      <div v-if="checkedClient" class="form__container">
        <ValidationObserver v-slot="{handleSubmit}">
          <form @submit.prevent="handleSubmit(handleVerifyClient)">
            <div class="siret">
              <ValidationProvider v-slot="{ errors }" rules="required|cod_client">
                <div class="form__field">
                  <input
                    id="siret"
                    v-model="verifyForm.clientCode"
                    :class="errors[0] && 'form__input--error'"
                    class="form__input form__input--siret"
                    name="siret"
                    placeholder=" "
                    type="text"
                    @blur="handleBlur('input5')"
                  >
                  <label class="form__label" for="siret">Numéro de compte {{ $t('site_name') }}</label>
                </div>

                <SvgImage
                  v-if="!errors[0] && displayIconCheck.input5"
                  class="form__icon form__icon--siret"
                  height="9"
                  icon="Icon_check"
                  width="16"
                />

                <SvgImage
                  v-if="errors[0]"
                  class="form__icon form__icon--siret"
                  height="13"
                  icon="Icon_fermer"
                  width="13"
                />
                <div v-if="errors[0]" class="error-msg error-msg--siret">{{ errors[0] }}</div>
              </ValidationProvider>
              <span @click="handleCodClientModal">
              <SvgImage
                class="siret__icon"
                height="20"
                icon="Icon_interrogation"
                width="20"
              />
            </span>
            </div>

            <ValidationProvider v-slot="{ errors }" rules="required|phone:10">
              <div class="form__field">
                <input
                  id="phoneNumber"
                  v-model="verifyForm.phone"
                  :class="errors[0] && 'form__input--error'"
                  class="form__input form__input--phone"
                  name="phoneNumber"
                  placeholder=" "
                  type="tel"
                  @blur="handleBlur('input3')"
                >
                <label class="form__label" for="phoneNumber">N° de mobile</label>
              </div>

              <SvgImage
                v-if="!errors[0] && displayIconCheck.input3"
                class="form__icon form__icon--phone"
                height="9"
                icon="Icon_check"
                width="16"
              />

              <SvgImage
                v-if="errors[0]"
                class="form__icon form__icon--phone"
                height="13"
                icon="Icon_fermer"
                width="13"
              />

              <div v-if="errors[0]" class="error-msg">{{ errors[0] }}</div>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" rules="required|email">
              <div class="form__field">
                <input
                  id="email"
                  v-model="verifyForm.email"
                  :class="errors[0] && 'form__input--error'"
                  class="form__input"
                  name="email"
                  placeholder=" "
                  type="email"
                  @blur="handleBlur('input4')"
                >
                <label class="form__label" for="email">Email</label>
              </div>

              <SvgImage
                v-if="!errors[0] && displayIconCheck.input4"
                class="form__icon"
                height="9"
                icon="Icon_check"
                width="16"
              />

              <SvgImage
                v-if="errors[0]"
                class="form__icon"
                height="13"
                icon="Icon_fermer"
                width="13"
              />

              <div v-if="errors[0]" class="error-msg error-msg--lg">{{ errors[0] }}</div>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" :rules="{ required: { allowFalse: false } }">
              <div class="form__radio">
                <input id="cgv" v-model="verifyForm.cgvFlag" :value="true" type="radio">
                <label class="form__radio--cgv" for="cgv" v-html="storeConfig.cgv_message"></label>
              </div>
              <div v-if="errors[0]" class="error-msg error-msg--lg">{{ errors[0] }}</div>
            </ValidationProvider>

            <SfLoader :loading="verifyFormLoading" class="form__loader">
              <SfButton
                :class="{'is-disabled--button': siretError || siretInfos.is_already_used}"
                class="sf-button sf-button--full-width btn-validate sf-button__primary"
                type="submit"
              >
                {{ $t("Validate") }}
              </SfButton>
            </SfLoader>
          </form>
        </ValidationObserver>

        <div class="rgpd">
          Conformément à la loi Informatique et Libertés et au RGPD, chaque Utilisateur dont les données auront été
          collectées par Cromology Services via le Site dispose d'un droit d’interrogation,
          d'accès, de rectification et à l’effacement de ses données ainsi que d’un droit à la limitation et
          d’opposition
          au traitement de ses données.
          Tout Utilisateur peut exercer ses droits en adressant sa demande à l'adresse mentionnée dans le paragraphe
          1.5.
          Droits des utilisateurs de nos <a class="rgpd__link" href="">mentions légales</a> relatives au traitement des
          données personnelles et en respectant les conditions indiquées.
        </div>
      </div>
    </div>

    <div class="accordion__container">
      <div @click="checkNotClient">
        <div :style="{cursor: 'pointer'}" class="accordion__title-container">
          <div>
            <input id="notClient" :checked="checkedNotClient" class="accordion__radio" name="notClient" type="radio"
                   value="notClient"/>
            <label for="notClient"></label>
            <span :class="checkedNotClient && 'accordion__title'">{{ $t("No, i don't have an account") }}</span>
          </div>
        </div>
      </div>
      <div v-if="checkedNotClient" class="form__container">
        <div class="form__title">{{ $t("Indicate your company coordonates found at the INSEE register") }}</div>
        <ValidationObserver v-slot="{handleSubmit}">
          <form @submit.prevent="handleSubmit(handleRegister)">
            <ValidationProvider v-slot="{ errors }" rules="required|alpha_dash|maxLastName:40">
              <div class="form__field">
                <input
                  id="lastName"
                  v-model="registerForm.lastName"
                  :class="errors[0] && 'form__input--error'"
                  class="form__input"
                  name="lastName"
                  placeholder=" "
                  type="text"
                  @blur="handleBlur('input1')"
                />
                <label class="form__label" for="lastName">Nom du dirigeant</label>
              </div>

              <SvgImage
                v-if="!errors[0] && displayIconCheck.input1"
                class="form__icon"
                height="9"
                icon="Icon_check"
                width="16"
              />

              <SvgImage
                v-if="errors[0]"
                class="form__icon"
                height="13"
                icon="Icon_fermer"
                width="13"
              />

              <div v-if="errors[0]" class="error-msg error-msg--lg">{{ errors[0] }}</div>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" rules="required|alpha_dash|maxFirstName:40">
              <div class="form__field">
                <input
                  id="firstName"
                  v-model="registerForm.firstName"
                  :class="errors[0] && 'form__input--error'"
                  class="form__input"
                  name="firstName"
                  placeholder=" "
                  type="text"
                  @blur="handleBlur('input2')"
                >
                <label class="form__label" for="firstName">Prénom du dirigeant</label>
              </div>

              <SvgImage
                v-if="!errors[0] && displayIconCheck.input2"
                class="form__icon"
                height="9"
                icon="Icon_check"
                width="16"
              />

              <SvgImage
                v-if="errors[0]"
                class="form__icon"
                height="13"
                icon="Icon_fermer"
                width="13"
              />

              <div v-if="errors[0]" class="error-msg error-msg--lg">{{ errors[0] }}</div>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" rules="required|phone:10">
              <div class="form__field">
                <input
                  id="phoneNumber"
                  v-model="registerForm.phone"
                  :class="errors[0] && 'form__input--error'"
                  class="form__input form__input--phone"
                  name="phoneNumber"
                  placeholder=" "
                  type="tel"
                  @blur="handleBlur('input3')"
                >
                <label class="form__label" for="phoneNumber">N° de mobile du dirigeant</label>
              </div>

              <SvgImage
                v-if="!errors[0] && displayIconCheck.input3"
                class="form__icon form__icon--phone"
                height="9"
                icon="Icon_check"
                width="16"
              />

              <SvgImage
                v-if="errors[0]"
                class="form__icon form__icon--phone"
                height="13"
                icon="Icon_fermer"
                width="13"
              />

              <div v-if="errors[0]" class="error-msg">{{ errors[0] }}</div>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" rules="required|email">
              <div class="form__field">
                <input
                  id="email"
                  v-model="registerForm.email"
                  :class="errors[0] && 'form__input--error'"
                  class="form__input"
                  name="email"
                  placeholder=" "
                  type="email"
                  @blur="handleBlur('input4')"
                >
                <label class="form__label" for="email">Email du dirigeant</label>
              </div>

              <SvgImage
                v-if="!errors[0] && displayIconCheck.input4"
                class="form__icon"
                height="9"
                icon="Icon_check"
                width="16"
              />

              <SvgImage
                v-if="errors[0]"
                class="form__icon"
                height="13"
                icon="Icon_fermer"
                width="13"
              />

              <div v-if="errors[0]" class="error-msg error-msg--lg">{{ errors[0] }}</div>
            </ValidationProvider>

            <div class="siret">
              <ValidationProvider v-slot="{ errors }" rules="required|siret:14">
                <div class="form__field">
                  <input
                    id="siret"
                    v-model="registerForm.siret"
                    :class="errors[0] && 'form__input--error'"
                    class="form__input form__input--siret"
                    name="siret"
                    placeholder=" "
                    type="text"
                    @blur="handleBlur('input5')"
                    @input="siretInputChange"
                  >
                  <label class="form__label" for="siret">N° Siret</label>
                </div>

                <SvgImage
                  v-if="!errors[0] && displayIconCheck.input5"
                  class="form__icon form__icon--siret"
                  height="9"
                  icon="Icon_check"
                  width="16"
                />

                <SvgImage
                  v-if="errors[0]"
                  class="form__icon form__icon--siret"
                  height="13"
                  icon="Icon_fermer"
                  width="13"
                />
                <div v-if="errors[0]" class="error-msg error-msg--siret">{{ errors[0] }}</div>
              </ValidationProvider>

              <span @click="handleSiretModal">
              <SvgImage
                class="siret__icon"
                height="20"
                icon="Icon_interrogation"
                width="20"
              />
            </span>
            </div>

            <SfLoader :loading="siretLoading" class="form__loader">
              <div v-if="displaySiretInfos">
                <div v-if="siretError" class="siret__response siret__response--error">
                  <div>{{ $t(siretError) }}</div>
                </div>
                <div v-else class="siret__response">
                  <div class="siret__response--bold">{{ siretInfos.company_name }}</div>
                  <div>{{ siretInfos.address }}</div>
                  <div>{{ siretInfos.post_code }}</div>
                  <div>{{ siretInfos.city }}</div>
                </div>
              </div>
            </SfLoader>

            <ValidationProvider v-slot="{ errors }" :rules="{ required: { allowFalse: false } }">
              <div class="form__radio">
                <input id="cgv" v-model="registerForm.cgvFlag" :value="true" type="radio">
                <label class="form__radio--cgv" for="cgv" v-html="storeConfig.cgv_message"></label>
              </div>
              <div v-if="errors[0]" class="error-msg error-msg--lg">{{ errors[0] }}</div>
            </ValidationProvider>

            <SfLoader :loading="registerFormLoading" class="form__loader">
              <SfButton
                :class="{'is-disabled--button': siretError || siretInfos.is_already_used}"
                class="sf-button sf-button--full-width btn-validate sf-button__primary"
                type="submit"
              >
                {{ $t("Validate") }}
              </SfButton>
            </SfLoader>
          </form>
        </ValidationObserver>

        <div class="rgpd">
          Conformément à la loi Informatique et Libertés et au RGPD, chaque Utilisateur dont les données auront été
          collectées par Cromology Services via le Site dispose d'un droit d’interrogation,
          d'accès, de rectification et à l’effacement de ses données ainsi que d’un droit à la limitation et
          d’opposition
          au traitement de ses données.
          Tout Utilisateur peut exercer ses droits en adressant sa demande à l'adresse mentionnée dans le paragraphe
          1.5.
          Droits des utilisateurs de nos <a class="rgpd__link" href="">mentions légales</a> relatives au traitement des
          données personnelles et en respectant les conditions indiquées.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, useContext, useFetch, useRouter } from '@nuxtjs/composition-api';
import { SfButton, SfLoader, SfRadio } from '@storefront-ui/vue';
import { useContent as useCmsContent, useForgotPassword } from '@/composables';
import { useProspect } from '../../composables/useProspect';
import { useCreateCustomer } from '../../composables/useCreateCustomer';
import { useEstablishment } from '@/composables/useEstablishment';
import useUiState from '@/composables/useUiState';
import getURLParams from '@/helpers/utils/getURLParams';
import SvgImage from '@/components/General/SvgImage'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { alpha_dash, digits, email, max, numeric, required } from 'vee-validate/dist/rules';
import PictimeSiretModal from "@/components/molecules/PictimeSiretModal";
import PictimeCodClientModal from "@/components/molecules/PictimeCodClientModal";
import { useConfig } from '~/composables';

//Gestion des messages d'erreur
extend('email', {
  ...email,
  message: 'Veuillez entrer une addresse mail valide (Ex: johndoe@domain.com)',
});

extend('required', {
  ...required,
  message: 'Ce champ est requis',
});

extend('phone', {
  ...digits,
  message: 'Le numéro de téléphone doit faire 10 caractères et comporter uniquement des chiffres de 0 à 9',
});

extend('siret', {
  ...digits,
  message: 'Le numéro de SIRET doit faire 14 caractères et comporter uniquement des chiffres de 0 à 9',
});

extend('cod_client', {
  ...numeric,
  message: 'Le numéro de compte doit comporter uniquement des chiffres de 0 à 9',
});

extend('alpha_dash', {
  ...alpha_dash,
  message: 'Certains caractères ne sont pas autorisés',
});

extend('maxFirstName', {
  ...max,
  message: 'Le prénom renseigné est trop long (40 caractères max)',
});

extend('maxLastName', {
  ...max,
  message: 'Le nom renseigné est trop long (40 caractères max)',
});

export default defineComponent({
  name: "CreateWebAccess",
  components: {
    PictimeSiretModal,
    PictimeCodClientModal,
    SfRadio,
    SfButton,
    SfLoader,
    SvgImage,
    ValidationProvider,
    ValidationObserver,
  },
  middleware: 'is-guest',
  setup() {
    const router                                                                             = useRouter();
    const { search: getProspect }                                                            = useProspect();
    const { search: register, verifyExistingAccount }                                        = useCreateCustomer();
    const { search: getSiretInfos }                                                          = useEstablishment();
    const { toggleSiretModal, isSiretModalOpen, toggleCodClientModal, isCodClientModalOpen } = useUiState();
    const { loadBlocks: loadCms }                                                            = useCmsContent();
    const { request: resetPassword, }                                                        = useForgotPassword();
    const { config }                                                                         = useConfig();

    const app    = useContext();
    const blocks = ref([]);

    let checkedClient    = ref(false);
    let checkedNotClient = ref(false);

    let verifyFormLoading   = ref(false)
    let registerFormLoading = ref(false)
    const mailStored        = getURLParams('mail') === 'Default' ? '' : getURLParams('mail');

    const checkClient = () => {
      resetForms();
      checkedClient.value    = true;
      checkedNotClient.value = false;
    }

    const checkNotClient = () => {
      resetForms();
      checkedClient.value    = false;
      checkedNotClient.value = true;
    }

    //Gestion des icônes validation
    let displayIconCheck = ref({
      input1: false,
      input2: false,
      input3: false,
      input4: false,
      input5: false
    });
    const handleBlur     = (_key) => {
      displayIconCheck.value[_key] = true;
    }

    //Ouverture/fermeture popin SIRET
    const handleSiretModal = () => {
      toggleSiretModal();
    };

    //Ouverture/fermeture popin Cod client
    const handleCodClientModal = () => {
      toggleCodClientModal();
    };

    //Informations du formulaire de vérification de compte
    let formError    = ref('')
    const verifyForm = ref({
      clientCode: '',
      phone: '',
      email: mailStored,
      cgvFlag: false
    });

    //Informations du formulaire d'inscription
    const registerForm = ref({
      lastName: '',
      firstName: '',
      phone: '',
      email: mailStored,
      siret: '',
      cgvFlag: false
    });

    //Informations encart SIRET
    const displaySiretInfos = ref(false);
    const siretLoading      = ref(false);
    let siretError          = ref('');
    const siretInfos        = ref({
      company_name: '',
      address: '',
      post_code: '',
      city_name: '',
      city_code: '',
      is_already_used: false
    });

    const resetForms = () => {
      verifyForm.value.clientCode = '';
      verifyForm.value.phone      = '';
      verifyForm.value.email      = mailStored;
      verifyForm.value.cgvFlag    = false;

      registerForm.value.lastName  = '';
      registerForm.value.firstName = '';
      registerForm.value.phone     = '';
      registerForm.value.email     = mailStored;
      registerForm.value.siret     = '';
      registerForm.value.cgvFlag   = false;

      siretInfos.value.company_name    = '';
      siretInfos.value.address         = '';
      siretInfos.value.post_code       = '';
      siretInfos.value.city_name       = '';
      siretInfos.value.city_code       = '';
      siretInfos.value.is_already_used = false;
    }

    const urlAccount = app.localePath('/acces-espace-pro');

    const siretInputChange = async (element) => {
      if (element.target.value.length === 14) {
        siretLoading.value = true;
        siretError.value   = false;

        const siretRes = await getSiretInfos(element.target.value);

        if (siretRes.errorMsg) {
          siretError.value = siretRes.errorMsg;
        } else {
          siretInfos.value.company_name    = siretRes.establishment.company_name;
          siretInfos.value.address         = siretRes.establishment.address;
          siretInfos.value.post_code       = siretRes.establishment.post_code;
          siretInfos.value.city            = siretRes.establishment.city_name;
          siretInfos.value.city_code       = siretRes.establishment.city_code;
          siretInfos.value.is_already_used = siretRes.establishment.is_already_used;
        }

        displaySiretInfos.value = true;
        siretLoading.value      = false;
      }
    }

    const handleVerifyClient = async () => {
      verifyFormLoading.value = true;

      // Appel Api Magento pour vérifier que le compte éxiste
      const verifyClientResult = await verifyExistingAccount({
          clientCode: verifyForm.value.clientCode,
          mail: verifyForm.value.email,
          cgv_flag: verifyForm.value.cgvFlag
        }
      );

      if (verifyClientResult.response === false || verifyClientResult.errors) {
        formError.value         = "Un accès web existe déjà pour ce compte. Connectez-vous à votre compte";
        verifyFormLoading.value = false;
      } else {
        const registerErrorMsg = config.value.register_success_message || '';
        formError.value = registerErrorMsg.replace('%s', verifyForm.value.email);
        await resetPassword({ email: verifyForm.value.email, token: null });
        verifyFormLoading.value = false;
      }
    }

    const handleRegister = async () => {
      if (siretError.value === false) {
        registerFormLoading.value = true;

        //Appel WS Prospect
        const res = await getProspect(
          {
            address: siretInfos.value.address,
            city_code: siretInfos.value.city_code,
            company_name: siretInfos.value.company_name,
            email_address: registerForm.value.email,
            firstname: registerForm.value.firstName,
            lastname: registerForm.value.lastName,
            mobile_phone: registerForm.value.phone,
            postcode: siretInfos.value.post_code,
            siret_number: registerForm.value.siret,
            uag: app.$config.platformTag,
            cgv_flag: registerForm.value.cgvFlag
          });

        if (res.data?.prospect !== null) {
          // Appel Api Magento pour création compte
          const registerResult = await register({
            email: registerForm.value.email,
            firstname: registerForm.value.firstName,
            lastname: registerForm.value.lastName,
            status: "En attente Cromology",
            cgv_flag: registerForm.value.cgvFlag
          });

          if (registerResult.data.createCustomerV2 != null) {
            router.push('registration-confirmed');
          } else if (registerResult.errors.length > 0) {
            formError.value           = "Un compte est déjà associé à cette adresse email. Merci d’en utiliser une autre.";
            registerFormLoading.value = false;
          }
        } else if (res.errors) {
          if (res.errors[0].debugMessage === 'Email already exists.') {
            formError.value = "Un compte est déjà associé à cette adresse email. Merci d’en utiliser une autre.";
          } else {
            formError.value = "Incident technique, veuillez réessayer ultérieurement. Pour toutes questions urgentes merci de contacter la hotline.";
          }

          registerFormLoading.value = false;
        }
      }
    };

    useFetch(async () => {
      blocks.value = await loadCms({ identifiers: ['footer-reassurance', 'footer-logo', 'footer-redirect', 'footer-social', 'footer-additional', 'text-espace-part', 'popin-siret-content', 'popin-cod-client-content'] });
    })

    return {
      verifyForm,
      registerForm,
      siretInfos,
      handleBlur,
      handleRegister,
      handleVerifyClient,
      handleSiretModal,
      handleCodClientModal,
      displayIconCheck,
      mailStored,
      formError,
      siretError,
      isSiretModalOpen,
      isCodClientModalOpen,
      displaySiretInfos,
      verifyFormLoading,
      registerFormLoading,
      blocks,
      siretInputChange,
      siretLoading,
      urlAccount,
      checkedClient,
      checkedNotClient,
      checkClient,
      checkNotClient,
      storeConfig: config,
    }
  },
});
</script>

<style lang="scss" scoped>
@import '@/modules/customer/pages/styles/create-web-access.scss';
</style>
