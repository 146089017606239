const getURLParam = (param) => {
  if (typeof window !== 'undefined') {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const res = urlParams.get(param);

    if (res === '' || res === null) {
      return 'Default'
    }

    return res;
  }
}

export default getURLParam;
