<template>
  <SfModal
    :cross="true"
    :visible="isSiretModalOpen"
    class="modal"
    @close="closeModal"
  >
    <transition
      mode="out-in"
      name="sf-fade"
    >
      <div>
        <HTMLContent
          :content="cmsBlock"
        />
      </div>
    </transition>
  </SfModal>
</template>
<script>
import { SfModal } from '@storefront-ui/vue';
import { useUiState } from '~/composables';
import HTMLContent from "@/components/HTMLContent";

export default {
  name: 'PictimeSiretModal',
  components: {
    HTMLContent,
    SfModal,
  },
  props: {
    cmsBlock: Boolean | String
  },
  setup() {
    const { isSiretModalOpen, toggleSiretModal } = useUiState();

    const closeModal = () => {
      toggleSiretModal();
    };

    return {
      closeModal,
      isSiretModalOpen,
    };
  },
};
</script>

<style lang="scss" scoped>

.modal {
  --modal-index: 3;
  --overlay-z-index: 3;

  ::v-deep .sf-modal__container {
    top: 50%;
    bottom: auto;
    height: fit-content;
    margin: 0 20px;
  }

  @include for-desktop {
    ::v-deep .sf-modal__container {
      margin: 0;
    }
  }
}
</style>
